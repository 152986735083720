@charset "utf-8";

// Import a Google Font
// @import url('https://fonts.googleapis.com/css?family=Nunito:400,700');
// @import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&display=swap');

// Set your brand colors
$purple: #8A4D76;
$dominosblue: #006591;
$dominosred: #e31837;
$pink: #FA7C91;
$brown: #757763;
// $beige-light: #D0D1CD;
// $beige-lighter: #EFF0EB;

// Update Bulma's global variables
// $family-sans-serif: "Nunito", sans-serif;
$family-sans-serif: "Arial Narrow",Arial,sans-serif;
// $family-sans-serif: Arial,sans-serif;


$grey-dark: $brown;
// $grey-light: $beige-light;
$primary: $dominosblue;
$danger: $dominosred;
$link: $pink;
$widescreen-enabled: false;
$fullhd-enabled: false;
$text: #282c34;
$title-color: #006491;
$label-color: #545454;
$box-padding: 1.25rem;
$message-body-color: #282c34;
$panel-heading-color: #FFF;
$panel-heading-size: 1em;
$panel-radius: 0px;
$panel-shadow: 0px;
$box-shadow: 0px;
$panel-heading-background-color: $dominosblue;
// Update some of Bulma's component variables
$body-background-color: #FFF;
$footer-background-color: #FFF;
$control-border-width: 2px;
$hero-body-padding: 2rem 0.5rem;
$button-disabled-background-color: hsl(0, 0%, 48%) !important;

// $input-border-color: transparent;
$input-shadow: none;

// @import '~@sweetalert2/theme-dark/dark.scss';
// @import '../node_modules/sweetalert2/src/sweetalert2.scss';

// // Import only what you need from Bulma
@import "../node_modules/bulma/sass/utilities/_all.sass";
@import "../node_modules/bulma/sass/base/_all.sass";
@import "../node_modules/bulma/sass/elements/_all.sass";
@import "../node_modules/bulma/sass/form/_all.sass";
@import "../node_modules/bulma/sass/grid/_all.sass";
@import "../node_modules/bulma/sass/components/_all.sass";
@import "../node_modules/bulma/sass/layout/_all.sass";
html {
    height: 100%;
    width: 100%;
    overflow: hidden; }
body {
    height: 100%;
    width: 100%;
    overflow: auto; }

input:hover {
    cursor: text; }
